/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, Title, SeparateLine, SeparateLineWrap, Text, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"David Klema - Elektromontážní práce - Ukázky naši práce"} description={"Ukázka naši práce"} seoTitle={"Rozmanitost, rozsah, schopnost a kvalita práce"}>
        <SiteHeader set="w3paydrlcin" currentLanguage={undefined} />

        <Column className="css-1yb56az --style2 --full" name={"uvod"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38215/32c19887c3464dbca38c9da174c65e85_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38215/32c19887c3464dbca38c9da174c65e85_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38215/32c19887c3464dbca38c9da174c65e85_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38215/32c19887c3464dbca38c9da174c65e85_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38215/32c19887c3464dbca38c9da174c65e85_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38215/32c19887c3464dbca38c9da174c65e85_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38215/32c19887c3464dbca38c9da174c65e85_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38215/32c19887c3464dbca38c9da174c65e85_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim6 --anim-s5 --center pl--0" style={{"maxWidth":""}} anim={"6"} animS={"5"}>
              
              <Subtitle className="subtitle-box fs--26 w--600 swpf--uppercase ls--50" content={"<span style=\"color: var(--white);\">David Klema</span>"}>
              </Subtitle>

              <Title className="title-box fs--43 w--500 lh--1" content={"Rozmanitost, rozsah, schopnost a kvalita práce<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"baimzm98787"} layout={"l3"}>
          
          <SeparateLineWrap style={{"paddingBottom":0,"paddingTop":0}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pt--20" style={{"paddingBottom":33,"marginBottom":0}} name={"meil5r635m"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Ukázka naši práce&nbsp;"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":38,"paddingBottom":27}} layout={"l8"} name={"kqm1aamezxs"}>
          
          <ColumnWrap className="column__flex --center el--4 flex--center" columns={"4"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Text className="text-box fs--18" content={"<span style=\"font-weight: bold; color: var(--white);\">Úprava odběrného místa pro FVE<br><br></span>"}>
              </Text>

              <Image style={{"maxWidth":1000}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38215/49677ace4b2e4c6aafabcbd01e976879_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/38215/49677ace4b2e4c6aafabcbd01e976879_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38215/49677ace4b2e4c6aafabcbd01e976879_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38215/49677ace4b2e4c6aafabcbd01e976879_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box fs--18" content={"<span style=\"font-weight: bold; color: var(--white);\">Výměna hlavního vedení v&nbsp;<br>rodinném domě</span><br>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38215/c63bee53379b42ca8131e2dbf4fe10c7_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/38215/c63bee53379b42ca8131e2dbf4fe10c7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38215/c63bee53379b42ca8131e2dbf4fe10c7_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38215/c63bee53379b42ca8131e2dbf4fe10c7_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box fs--18" content={"<span style=\"color: var(--white); font-weight: bold;\">Výměna Odběrného místa a instalace wallboxu</span>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38215/8c5ab31b99d843c1a9ff3ab9593a6d86_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/38215/8c5ab31b99d843c1a9ff3ab9593a6d86_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38215/8c5ab31b99d843c1a9ff3ab9593a6d86_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38215/8c5ab31b99d843c1a9ff3ab9593a6d86_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box fs--18" content={"<span style=\"font-weight: bold; color: var(--white);\">Kompletní instalace a zapojení&nbsp; FVE systému<br></span>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38215/2515f92c220143559cfcd1dec425d196_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/38215/2515f92c220143559cfcd1dec425d196_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38215/2515f92c220143559cfcd1dec425d196_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38215/2515f92c220143559cfcd1dec425d196_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box fs--18" content={"<span style=\"font-weight: bold; color: var(--white);\">Úprava odběrného místa pro FVE<br></span><br>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38215/b522d52855f04875b6a054e3cbf76c91_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/38215/b522d52855f04875b6a054e3cbf76c91_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38215/b522d52855f04875b6a054e3cbf76c91_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38215/b522d52855f04875b6a054e3cbf76c91_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box fs--18" content={"<span style=\"font-weight: bold; color: var(--white);\">Úprava odběrného místa pro FVE<br><br></span>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38215/ad00792a33ee4e6b88f5eb870706bb94_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/38215/ad00792a33ee4e6b88f5eb870706bb94_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38215/ad00792a33ee4e6b88f5eb870706bb94_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38215/ad00792a33ee4e6b88f5eb870706bb94_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box fs--18" content={"<span style=\"font-weight: bold; color: var(--white);\">Zřízení Zemnícího bodu<br><br></span>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38215/bf2f6411c15942b9be744a455c1c5df4_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/38215/bf2f6411c15942b9be744a455c1c5df4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38215/bf2f6411c15942b9be744a455c1c5df4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38215/bf2f6411c15942b9be744a455c1c5df4_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box fs--18" content={"<span style=\"font-weight: bold; color: var(--white);\">Rekonstrukce elektroinstalace v rodinném domě<br></span><br><br>"}>
              </Text>

              <Image style={{"maxWidth":1000}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38215/52dee466001d4a6bae702bd841982716_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/38215/52dee466001d4a6bae702bd841982716_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38215/52dee466001d4a6bae702bd841982716_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38215/52dee466001d4a6bae702bd841982716_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38215/52dee466001d4a6bae702bd841982716_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"06hh9n34i7z"} layout={"l3"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column style={{"paddingTop":25,"paddingBottom":14}} layout={"l1"} name={"zq0csfvrlcn"}>
          
          <ColumnWrap className="column__flex --left el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"David Klema - Elektromontážní práce"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--30" content={"Kontakt"}>
              </Title>

              <Text className="text-box fs--18" content={"<span style=\"color: var(--white); font-weight: bold;\">Tovární 2446/2\n<br>Karviná Nové - Město<br>735 06<br>+420 739 294 592<br>davidklema@seznam.cz<br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}